// Variables
@import "variables";

.blog-title {
    color: $primary; }
.article-list {
    hr {
        border-top-color: #a4a4a4; } }
.article {
    small {
        color: #a5a5a5;
        font-size: 1em; }
    .article-resume {
        text-align: justify;
        a {
            color: $primary; } } }
.blog-sidebar {
    h3 {
        font-weight: lighter;
        &::after {
            content: "";
            width: 60px;
            height: 1px;
            background-color: black;
            display: block;
            margin: 15px 0px; } }
    .list {
        list-style: none;
        a {
            color: black; } } }
.featured {
    p {
        font-size: 0.85em; } }
.blog-main {
    h2 {
        color: $primary; } }
