
.service-detail {
    border: 1px solid $secondary;

    .text-desc {
        color: #a4a4a4; } }

.service-desc {
    h3 {
        &:after {
            margin: 0 auto;
            width: 50px;
            margin-top: 15px; } } }

