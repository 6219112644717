// Variables
@import "variables";

.owl-prev, .owl-next {
    position: absolute;
    top: 40%;
    display: block !important;
    border: 0px solid black;
    background: none !important; }

.owl-prev {
    margin-left: 10px !important; }

.owl-next {
    right: 10px; }

.owl-prev i, .owl-next i {
    transform: scale(5,6);
    color: white; }

.index-img.active {
    border: 2px solid $primary;
    box-sizing: border-box; }
