
// Fonts
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap/scss/bootstrap"; 
// Font Awesome
@import "node_modules/font-awesome/scss/font-awesome"; 
// Owl carousel 2
@import "node_modules/owl.carousel2/src/scss/owl.carousel"; 
@import "node_modules/owl.carousel2/src/scss/owl.theme.default"; 
@import "custom_owl";
// Magnific popup
@import "node_modules/magnific-popup/src/css/main"; 
// iCheck
@import "node_modules/icheck/skins/line/green"; 
// NiceSelect
@import "node_modules/jquery-nice-select/scss/nice-select"; 
// CustomSelect
@import "node_modules/custom-select/build/custom-select"; 

@import "common"; 
@import "services"; 
@import "blog";
@import "home";