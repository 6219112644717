.home-title {
    text-transform: uppercase;
    &::after {
        content: '';
        display: block;
        width: 75px;
        border-top: 1px solid $primary;
        margin: 1rem auto; } }
.highlighted-news {
    .news-summary, .news-link {
        font-size: 0.9em;
        color: #a0a0a0; }
    .news-link {
        color: $primary; }
    .owl-dots {
        .owl-dot {
            span {
                background-color: #869791; }
            &.active {
                span {
                    background-color: $primary; } } } }
    .owl-stage-outer {
        padding-top: 1rem;
        padding-bottom: 1rem;
        .owl-item {
            padding: 5px; } }
    .card {
        border: 0;
        box-shadow: 0px 0px 25px -5px #495057;
        .card-img-top {
            .date-label {
                background: $primary;
                position: absolute;
                top: 20px;
                padding: 5px 15px;
                color: white;
                font-weight: 400; } } } }
