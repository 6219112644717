// Redifir el color primary de la web.
$primary: #a2c500;
$secondary: #eeeeee;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 200 !default;

// Botons 
$btn-border-radius:     0 !default;
$btn-border-radius-lg:  0 !default;
$btn-border-radius-sm:  0 !default;
$btn-font-weight:       300 !default;
$btn-font-size:         14 !default;

// Fonts
$font-family-base: 'Poppins' !default;
$font-weight-base: 300 !default;

// Dropdowns
$dropdown-min-width: auto !default;
$dropdown-bg: rgba(0, 0, 0, 0.15);
$dropdown-item-padding-y: .05rem !default;
$dropdown-border-radius: 0;

$navbar-light-color: #fff;
$navbar-light-active-color: $primary;

// Headers
$headings-margin-bottom: 0;

// inputs
$input-border-radius: 0;