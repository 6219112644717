.dropdown-item {
  font-weight: $font-weight-base; }

.contact-header {
    * {
        font-size: $font-size-sm;
        color: $white; } }

h3 {
    font-size: 23px;

    &:after {
        content: '';
        display: block;
        width: 15%;
        margin-top: 5px;
        border-top: 1px solid $primary; } }

.text-xs {
    font-size: $font-size-sm !important; }

#slides {
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.45);
        top: 0;
        z-index: 8; } }

.slides-pagination, .slides-navigation {
    z-index: 10; }
.menu {
    position: absolute;
    z-index: 9;
    width: 100%;
    top: 0;

    .main-menu {
        .navbar {
            padding: 0.5rem 0;
            margin-left: -0.5rem;
            margin-right: -0.5rem;

            .nav-item.active {
                &:after {
                    content: '';
                    display: block;
                    width: 85%;
                    margin: 0 auto;
                    border: 2px solid $primary; } } } } }

.newsletter-banner {
    h4 {
        color: $white;
        font-weight: 300; }

    .btn-primary {
        border-color: $white; } }

footer {

    h4 {
        font-size: 18px;

        &:after {
            content: '';
            display: block;
            width: 50px;
            border-top: 1px solid;
            margin-top: 1rem; } }
    ul {
        li {
            a {
                color: #000; } }

        &.custom-dots {
            li {
                &:before {
                    content: url('../img/list-icon.png'); } } }

        &.social {
            li {
                background-color: #d8d8d8;
                width: 30px;
                height: 30px;
                border-radius: 50%;

                &:hover {
                    background-color: $primary; }

                a {
                    color: #fff;
                    line-height: 30px; } } } }

    .description {
        font-size: $font-size-sm; }

    .horari {
        position: relative;

        &:before {
            content: url('../img/watch.png');
            position: absolute;
            left: 0; }

        .subtitle {
            p {
                color: #737373;
                font-size: 0.9em; } } }

    .marker {
        position: relative;

        &:before {
            content: url('../img/marker.png');
            position: absolute;
            left: 0; } }

    .phone {
        position: relative;

        &:before {
            content: url('../img/phone.png');
            position: absolute;
            left: 0; } }

    .envelope {
        position: relative;

        &:before {
            content: url('../img/envelope.png');
            position: absolute;
            left: 0; } } }

.credits {
    p {
        font-size: $font-size-sm; } }

.grey-square {
    background: #eeeeee;
    h2 {
        color: $primary;
        font-weight: 300; } }

.card, .card-header {
    border-radius: 0; }

.cursor-pointer {
    cursor: pointer; }

.nav-tabs .nav-link {
    border-radius: 0;
    color: #495057;
    &.active {
        color: #a2c500; } }

.tab-content {
    border: 1px solid #dee2e6;
    border-top: none;
    font-size: 0.9em; }

.btn-inverse {
    border-color: $primary;
    color: $primary;
    background: white;
    &:hover {
        color: white;
        background: $primary; } }

.detail-button {
    border: 1px solid $primary;
    cursor: pointer; }

.black-underline {
    &:after {
        margin: 0 auto;
        width: 50px;
        margin-top: 15px;
        border-top: 1px solid black; } }

.publication-card {
    border: 0;
    box-shadow: 0px 0px 25px -5px #495057;
    .card-img-top {
        position: relative;
        .address-caption {
            position: absolute;
            bottom: 10px;
            width: 100%;
            color: white;
            z-index: 99;
            background: rgba(0,0,0,0.6);
            font-size: 0.9em;
            font-weight: 600;
            .address::before {
                content: url('../img/marker-white.png');
                margin-right: 5px; }
            .images-counter::before {
                content: url('../img/camera-white.png');
                margin-right: 5px; } } }
    .card-body {
        .card-title, .card-price {
            letter-spacing: 2px;
            font-size: 1em;
            font-weight: 600; }
        .card-price, .card-text, .rooms-num, .bathrooms-num, .surface {
            color: #ababab; }
        .card-text {
            font-size: 0.9em;
            line-height: 1.2; }
        .rooms-num, .bathrooms-num, .surface {
            &::before {
                margin-right: 5px; } }
        .rooms-num::before {
            content: url('../img/rooms-grey.png'); }
        .bathrooms-num::before {
            content: url('../img/bathrooms-grey.png'); }
        .surface::before {
            content: url('../img/surface-grey.png'); }
        .share-button {
            position: absolute;
            right: 20px;
            margin-top: 5px; } } }
.price-container {
    font-size: 1.8em;
    font-weight: 600;
    span {
        color: #ababab;
        font-size: 0.9rem;
        font-weight: 600; } }
.ask-form {
    font-size: 0.9em;
    input, textarea {
        font-size: 0.9em; }
    .card-header {
        font-size: 1.2em; } }
.owl-item {
    .over-img {
        display: none;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%; }
    &:hover .over-img {
        display: flex !important;
        justify-content: center;
        align-items: center;
        img {
            width: auto; } } }
.share-menu {
    background: white;
    border-color: $primary;
    padding: 1rem; }
.filter-form {
    label {
        font-size: 1.2em;
        font-weight: bold;
        letter-spacing: 2px; }
    input, select {
        font-size: 0.9em !important;
        border-radius: 0; } }
.min-rooms-selector {
    width: 100%;
    label {
        font-size: 1em;
        width: 20%;
        border-color: #eeeeee;
        &.btn {
            background-color: white;
            color: #727272;
            font-weight: 300;
            &.active {
                background-color: $primary !important;
                border-color: $primary !important; } } } }
.property-type-check {
    label {
        color: #727272;
        font-weight: 300;
        font-size: 0.9em; } }
.btn.btn-filter {
    width: 100%;
    color: $primary;
    font-size: 1.2em;
    font-weight: 400;
    border: 1px solid $primary;
    text-transform: uppercase; }

.icheckbox_line-green {
    background: white !important;
    color: #727272;
    font-size: 1em;
    border-radius: 0;
    padding: 10px 30px;
    border: 1px solid #eeeeee;
    font-size: 0.9em !important;
    &.checked, &:hover {
        background: $primary !important;
        color: white;
        border: 1px solid $primary; }
    &.checked {
        .icheck_line-icon {
            background: url(/images/vendor/icheck/skins/line/line.png) no-repeat !important;
            background-position: -15px 0px !important;
            height: 13px !important;
            margin: -5px 0 0 0 !important;
            i {
                display: none !important; } } }
    .icheck_line-icon {
        background: none !important;
        height: 15px !important;
        margin: -7px 0 0 0 !important; } }
.property-type-check {
    padding-left: 0 !important; }
.top-form {
    label {
        color: #a4a4a4;
        font-size: 0.9em; } }
.contact-data {
    border: 1px solid #cccccc;
    label {
        font-weight: bold;
        font-size: 1.1em;
        &::after {
            content: "";
            width: 60px;
            height: 2px;
            background-color: $primary;
            display: block;
            margin: 15px auto; } } }
.dark-img-bck {
    position: relative;
    z-index: 2;
    background-position: center center;
    .bg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,0.7);
        width: 100%;
        height: 100%; } }
.text-white {
    color: white; }
.py-6rem {
    padding-top: 6rem;
    padding-bottom: 6rem; }
#slides {
    .slides-navigation {
        z-index: 99999; }
    .slides-pagination {
        margin-bottom: 10px;
        a {
            border: 0;
            background: #869791;
            width: 15px;
            height: 15px;
            &.current {
                background: $primary; } } } }
.slider-search-menu {
    position: absolute;
    z-index: 99;
    left: 50%;
    top: 60%;
    transform: translate(-50%,-50%);
    .white-square {
        background: rgba(255,255,255,0.8);
        max-height: 200px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 10px; }
        &::-webkit-scrollbar-track {
            background: #cbcbcb; }
        &::-webkit-scrollbar-thumb {
            background: black;
            border-radius: 12px; }
        .icheckbox_line-green, .iradio_line-green {
            background: transparent !important;
            color: black;
            font-size: 1.2rem !important;
            border-radius: 0;
            box-sizing: border-box !important;
            -moz-box-sizing: border-box !important;
            -webkit-box-sizing: border-box !important;
            border: none;
            .icheck_line-icon {
                display: none; }
            &.checked {
                font-weight: 400;
                border: 1px solid black; } } }
    .slider-search-button {
        font-size: 1.4rem;
        font-weight: 400; } }
.howitworks {
    font-weight: bold;
    color: #92a927;
    font-size: 1.2em;
    letter-spacing: 2px; }
.custom-select-container {
    width: 100%;
    .custom-select-opener, .custom-select-panel {
        background-color: white;
        border: solid 1px #e8e8e8; }
    .custom-select-opener {
        position: relative;
        &:after {
            border-bottom: 2px solid #999;
            border-right: 2px solid #999;
            content: '';
            display: block;
            height: 5px;
            margin-top: -4px;
            pointer-events: none;
            position: absolute;
            right: 12px;
            top: 50%;
            transform-origin: 66% 66%;
            transform: rotate(45deg);
            transition: all 0.15s ease-in-out;
            width: 5px; } }
    &.is-open .custom-select-panel {
        z-index: 2; } }
.infowindow_title, .infowindow_content, .infowindow_price {
    font-size: 14px;
    margin-bottom: 5px; }
.infowindow_title {
    color: $primary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:after {
        content: none; } }
.infowindow_price {
    color: #b2b2b2; }
.infowindow_content {
    color: #c1c1c1;
    max-height: 65px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
    text-align: justify; }
.no-hover:hover {
    text-decoration: none; }
.mobile-info-window {
    background: white;
    border: 2px solid $primary;
    padding: 10px;
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 9999; }
.product_info.higlighted {
    border: 2px solid $primary; }

.card-header-footer {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    font-size: unset; }

.card-header-footer {
    strong {
        font-size: 19px; } }

.tab_new {
    position: fixed;
    background: black;
    color: white;
    padding: 5px 30px 5px 30px;
    margin-top: 30px; }
